var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { "no-gutters": "" } },
    [
      _vm.center === "JULIE"
        ? _c("JulieExcavationDetails", {
            attrs: { "map-view": _vm.mapView, "is-edit-view": _vm.isEditView },
            on: {
              excavationDetailsCompleted: function ($event) {
                return _vm.$emit("excavationDetailsCompleted")
              },
              excavationDetailsBack: function ($event) {
                return _vm.$emit("excavationDetailsBack")
              },
              editLocation: function ($event) {
                return _vm.$emit("editLocation")
              },
            },
          })
        : _vm._e(),
      _vm.center === "OUPS"
        ? _c("OupsExcavationDetails", {
            attrs: { "map-view": _vm.mapView, "is-edit-view": _vm.isEditView },
            on: {
              excavationDetailsCompleted: function ($event) {
                return _vm.$emit("excavationDetailsCompleted")
              },
              excavationDetailsBack: function ($event) {
                return _vm.$emit("excavationDetailsBack")
              },
              editLocation: function ($event) {
                return _vm.$emit("editLocation")
              },
            },
          })
        : _vm._e(),
      _vm.center === "UTAH"
        ? _c("UtahExcavationDetails", {
            attrs: { "map-view": _vm.mapView, "is-edit-view": _vm.isEditView },
            on: {
              excavationDetailsCompleted: function ($event) {
                return _vm.$emit("excavationDetailsCompleted")
              },
              excavationDetailsBack: function ($event) {
                return _vm.$emit("excavationDetailsBack")
              },
              editLocation: function ($event) {
                return _vm.$emit("editLocation")
              },
            },
          })
        : _vm._e(),
      _vm.center === "NCOCC"
        ? _c("NcoccExcavationDetails", {
            attrs: { "map-view": _vm.mapView, "is-edit-view": _vm.isEditView },
            on: {
              excavationDetailsCompleted: function ($event) {
                return _vm.$emit("excavationDetailsCompleted")
              },
              excavationDetailsBack: function ($event) {
                return _vm.$emit("excavationDetailsBack")
              },
              editLocation: function ($event) {
                return _vm.$emit("editLocation")
              },
            },
          })
        : _vm._e(),
      _vm.center === "UNCC"
        ? _c("UnccExcavationDetails", {
            attrs: { "map-view": _vm.mapView, "is-edit-view": _vm.isEditView },
            on: {
              excavationDetailsCompleted: function ($event) {
                return _vm.$emit("excavationDetailsCompleted")
              },
              excavationDetailsBack: function ($event) {
                return _vm.$emit("excavationDetailsBack")
              },
              editLocation: function ($event) {
                return _vm.$emit("editLocation")
              },
            },
          })
        : _vm._e(),
      _vm.center === "VUPS"
        ? _c("VupsExcavationDetails", {
            attrs: { "map-view": _vm.mapView, "is-edit-view": _vm.isEditView },
            on: {
              excavationDetailsCompleted: function ($event) {
                return _vm.$emit("excavationDetailsCompleted")
              },
              excavationDetailsBack: function ($event) {
                return _vm.$emit("excavationDetailsBack")
              },
              editLocation: function ($event) {
                return _vm.$emit("editLocation")
              },
            },
          })
        : _vm._e(),
      _vm.center === "USAS"
        ? _c("UsasExcavationDetails", {
            attrs: { "map-view": _vm.mapView, "is-edit-view": _vm.isEditView },
            on: {
              excavationDetailsCompleted: function ($event) {
                return _vm.$emit("excavationDetailsCompleted")
              },
              excavationDetailsBack: function ($event) {
                return _vm.$emit("excavationDetailsBack")
              },
              editLocation: function ($event) {
                return _vm.$emit("editLocation")
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }