<template>
  <v-row no-gutters>
    <JulieExcavationDetails
      v-if="center === 'JULIE'"
      :map-view="mapView"
      :is-edit-view="isEditView"
      @excavationDetailsCompleted="$emit('excavationDetailsCompleted')"
      @excavationDetailsBack="$emit('excavationDetailsBack')"
      @editLocation="$emit('editLocation')"
    />
    <OupsExcavationDetails
      v-if="center === 'OUPS'"
      :map-view="mapView"
      :is-edit-view="isEditView"
      @excavationDetailsCompleted="$emit('excavationDetailsCompleted')"
      @excavationDetailsBack="$emit('excavationDetailsBack')"
      @editLocation="$emit('editLocation')"
    />
    <UtahExcavationDetails
      v-if="center === 'UTAH'"
      :map-view="mapView"
      :is-edit-view="isEditView"
      @excavationDetailsCompleted="$emit('excavationDetailsCompleted')"
      @excavationDetailsBack="$emit('excavationDetailsBack')"
      @editLocation="$emit('editLocation')"
    />
    <NcoccExcavationDetails
      v-if="center === 'NCOCC'"
      :map-view="mapView"
      :is-edit-view="isEditView"
      @excavationDetailsCompleted="$emit('excavationDetailsCompleted')"
      @excavationDetailsBack="$emit('excavationDetailsBack')"
      @editLocation="$emit('editLocation')"
    />
    <UnccExcavationDetails
      v-if="center === 'UNCC'"
      :map-view="mapView"
      :is-edit-view="isEditView"
      @excavationDetailsCompleted="$emit('excavationDetailsCompleted')"
      @excavationDetailsBack="$emit('excavationDetailsBack')"
      @editLocation="$emit('editLocation')"
    />
    <VupsExcavationDetails
      v-if="center === 'VUPS'"
      :map-view="mapView"
      :is-edit-view="isEditView"
      @excavationDetailsCompleted="$emit('excavationDetailsCompleted')"
      @excavationDetailsBack="$emit('excavationDetailsBack')"
      @editLocation="$emit('editLocation')"
    />
    <UsasExcavationDetails
      v-if="center === 'USAS'"
      :map-view="mapView"
      :is-edit-view="isEditView"
      @excavationDetailsCompleted="$emit('excavationDetailsCompleted')"
      @excavationDetailsBack="$emit('excavationDetailsBack')"
      @editLocation="$emit('editLocation')"
    />
  </v-row>
</template>
<script>
import * as wellknown from "wellknown";
import UserData from "@/mixins/UserData.vue";
export default {
  components: {
    JulieExcavationDetails: () =>
      import("./centers/julie/JulieExcavationDetails.vue"),
    OupsExcavationDetails: () =>
      import("./centers/ohio/OupsExcavationDetails.vue"),
    UtahExcavationDetails: () =>
      import("./centers/utah/UtahExcavationDetails.vue"),
    NcoccExcavationDetails: () =>
      import("./centers/ncocc/NcoccExcavationDetails.vue"),
    UnccExcavationDetails: () => import("./centers/uncc/UnccExcavationDetails"),
    VupsExcavationDetails: () =>
      import("./centers/vups/VupsExcavationDetails.vue"),
    UsasExcavationDetails: () =>
      import("./centers/usas/UsasExcavationDetails.vue"),
  },
  mixins: [UserData],
  props: {
    mapView: {
      type: String,
      default: () => "",
    },
    isEditView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      center: this.$store.state.Ticket.ticketData.center,
    };
  },
  async created() {
    if (
      !this.$store.state.Ticket.ticketData.streetLocation ||
      (!this.$store.state.Ticket.excavationDetails.work_date &&
        !this.$store.state.Ticket.ticketData.streetLocation)
    )
      await this.getLocation();
    if (
      !this.$store.state.Ticket.excavationDetails.associateProject ||
      Object.keys(this.$store.state.Project.project).length == 0
    )
      if (this.checkPermission("viewProject")) {
        await this.getProjectData();
      }
  },
  methods: {
    async getProjectData() {
      let tempProjectList = [];
      const projectDataResponse = await this.$store.dispatch(
        "getCompanyProject"
      );
      if (projectDataResponse.status !== "error") {
        if (projectDataResponse && projectDataResponse.length > 0) {
          tempProjectList = projectDataResponse.map(
            ({ project_id, project_name }) => {
              return { project_id, project_name };
            }
          );
          this.$store.commit("setProjectData", tempProjectList);
        }
      }
    },
    async getLocation() {
      this.$store.state.Ticket.ticketData.streetLocation = {};
      if(this.center === 'NCOCC' || this.center === 'OUPS') return
      const bodyObject = {};
      bodyObject.body = {
        center: this.$store.state.Ticket.ticketData.center,
        geom: wellknown.stringify(this.$store.state.Ticket.geoJson.features[0]),
      };
      const geocodeDescribeResult = await this.$store.dispatch(
        "getGeoCoderDescribe",
        bodyObject
      );
      if (geocodeDescribeResult.status !== "error") {
        let obj = Object.assign({}, this.$store.state.Ticket.ticketData);
        obj["streetLocation"] = geocodeDescribeResult;
        this.$store.commit("setTicketData", {});
        this.$store.commit("setTicketData", obj);
      }
    },
  },
};
</script>
